function Services() {
  return (
    <section id="services">
      <div className="container">
        <div className="section-header wow fadeInUp">
          <h3>Services</h3>
          <p>
            Techknock is an experienced Software Consultancy company helping
            startups and business owners design, develop & support their
            software with the aim of creating a profitable & sustainable
            business.
            {/* We do this by being more than just software developers. We
            are experienced business coaches in our own right having built and
            sold multiple software products ourselves beforehand. */}
            <br />
            <br />
            <b>
              Techknock is a trusted leader for building the future of
              performance user experiences.
            </b>
          </p>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <div className="card">
              <div className="face front-face">
                <img
                  src="/img/services/mobile.png"
                  alt=""
                  className="profile"
                />
                <div className="pt-3 text-uppercase name">
                  MOBILE AND WEB DEVELOPMENT
                </div>
                {/* <div className="designation">Finance Director</div> */}
              </div>
              <div className="face back-face">
                <span className="fa fa-quote-left"></span>
                <div className="testimonial">
                  Our Mobile and Web Development services deliver robust,
                  user-friendly applications tailored to your business needs. We
                  combine innovative design with cutting-edge technology to
                  create seamless digital experiences.
                </div>
                <span className="fa fa-quote-right"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="face front-face">
                <img
                  src="/img/services/branding.png"
                  alt=""
                  className="profile"
                />
                <div className="pt-3 text-uppercase name">
                  Branding & Graphic Design
                </div>
                {/* <div className="designation">Finance Director</div> */}
              </div>
              <div className="face back-face">
                <span className="fa fa-quote-left"></span>
                <div className="testimonial">
                  Our Branding, Marketing, and Graphic Design services craft
                  compelling visual identities and strategies. We blend
                  creativity with market insights to elevate your brand and
                  engage your audience effectively.
                </div>
                <span className="fa fa-quote-right"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="face front-face">
                <img src="/img/services/web.png" alt="" className="profile" />
                <div className="pt-3 text-uppercase name">
                  Software Development
                </div>
                {/* <div className="designation">Finance Director</div> */}
              </div>
              <div className="face back-face">
                <span className="fa fa-quote-left"></span>
                <div className="testimonial">
                  We have expertise in building custom, clean and well-coded
                  software solutions tailored for your business needs. The
                  company aims to provide best digital solutions to its clients
                  with the help of various technologies
                </div>
                <span className="fa fa-quote-right"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="face front-face">
                <img src="/img/services/cloud.png" alt="" className="profile" />
                <div className="pt-3 text-uppercase name">AWS and Cloud</div>
                {/* <div className="designation">Finance Director</div> */}
              </div>
              <div className="face back-face">
                <span className="fa fa-quote-left"></span>
                <div className="testimonial">
                  We are a team of certified AWS Solutions Architects, AWS
                  Developer Professionals, Cloud Practitioners, DevOps and Site
                  Reliability Engineer who helps your business to build, scale
                  and operate applications on AWS
                </div>
                <span className="fa fa-quote-right"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="face front-face">
                <img
                  src="/img/services/data-sciences.png"
                  alt=""
                  className="profile"
                />
                <div className="pt-3 text-uppercase name">Data Sciences</div>
                {/* <div className="designation">Finance Director</div> */}
              </div>
              <div className="face back-face">
                <span className="fa fa-quote-left"></span>
                <div className="testimonial">
                  Leverage the power of data science to make fast-paced, high
                  quality decision strategic driven by data. We will give you an
                  end to end solution for your business problem, where we will
                  collect the data, process it and setup the solutions for you
                </div>
                <span className="fa fa-quote-right"></span>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="face front-face">
                <img src="/img/services/hr.png" alt="" className="profile" />
                <div className="pt-3 text-uppercase name">Talent on Demand</div>
                {/* <div className="designation">Finance Director</div> */}
              </div>
              <div className="face back-face">
                <span className="fa fa-quote-left"></span>
                <div className="testimonial">
                  Techknock help companies increase their bottom line and market
                  value by adding more value to the talent acquisition process
                  and find top notch talent for any company.
                </div>
                <span className="fa fa-quote-right"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
