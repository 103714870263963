import { useEffect } from "react";
import $ from "jquery";

function ReactDeveloper() {
  useEffect(() => {
    $("html, body").animate({ scrollTop: "0" }, 0);
  }, []);

  return (
    <>
      <section id="intro" className="custom-page">
        <div className="intro-container">
          <div
            id="introCarousel"
            className="carousel  slide carousel-fade"
            data-ride="carousel"
          >
            <div className="carousel-inner" role="listbox">
              <div
                className="carousel-item active custom-content"
                style={{
                  backgroundImage:
                    "url('/img/careers/react-developer-bkg.jpg')",
                }}
              >
                <div className="carousel-container">
                  <div className="carousel-content">
                    <h2>React Developer</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services">
        <div className="container">
          <div className="section-header wow fadeInUp">
            <h3>Job Description</h3>
            <p style={{ textAlign: "justify", paddingBottom: "0px" }}>
              We are looking for a skilled react.js developer to join our
              front-end development team at{" "}
              <b>
                <a href="https://techknock.co.uk/">Techknock</a>
              </b>
              . In this role, you will be responsible for developing and
              implementing user interface components using React.js concepts and
              workflows such as Redux, Flux, and Webpack. You will also be
              responsible for profiling and improving front-end performance and
              documenting our front-end codebase.
              <br />
              To ensure success as a react.js developer, you should have
              in-depth knowledge of JavaScript and React concepts, excellent
              front-end coding skills, and a good understanding of progressive
              web applications. <br />
              <br />
            </p>
            <h3>Responsibilities</h3>
            <p style={{ textAlign: "left", paddingBottom: "0px" }}>
              <li>
                Meeting with the development team to discuss user interface
                ideas and applications.
              </li>
              <li>Reviewing application requirements and interface designs.</li>
              <li>
                Developing and implementing highly responsive user interface
                components using react concepts.
              </li>
              <li>
                Writing application interface codes using JavaScript following
                react.js workflows.
              </li>
              <li>
                Troubleshooting interface software and debugging application
                codes.
              </li>
              <li>
                Developing and implementing front-end architecture to support
                user interface concepts.
              </li>
              <li>
                Communicate with followers, respond to queries in a timely
                manner and monitor customer reviews
              </li>
              <li>Monitoring and improving front-end performance.</li>
              <li>Documenting application changes and developing updates.</li>
            </p>
            <div className="row">
              <div className="col">
                <img
                  src="/img/careers/react-developer-detail.jpg"
                  alt=""
                  className="react-developer-detail.jpg.jpeg"
                  style={{ width: "inherit", marginBottom: "5%" }}
                />
              </div>
            </div>
            <p style={{ textAlign: "justify", paddingBottom: "0px" }}>
              Ultimately, a top-class react.js developer at{" "}
              <b>
                <a href="https://techknock.co.uk/">Techknock</a>
              </b>{" "}
              should be able to design and build modern user interface
              components to enhance application performance. Ultimately, you
              should be able to handle{" "}
            </p>
            <h2>
              Send your resume at{" "}
              <b>
                <a href="mailto:info@techknock.com">Techknock</a>
              </b>{" "}
            </h2>
          </div>
        </div>
      </section>
    </>
  );
}

export default ReactDeveloper;
