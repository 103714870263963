import About from "./About";
import Carousel from "./Carousel";
import Contact from "./Contact";
import Services from "./Services";
import Clients from "./Clients";
import Testimonials from "./Testimonials";
function Home() {
  return (
    <main id="main">
     
      <Carousel />
      <About />
      {/* <Accreditations /> */}

      <Services />
      <Clients />
      <Testimonials />
      {/* <Team /> */}

      <Contact />
    </main>
  );
}

export default Home;
