import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Blog from "./components/Blog";
import CustomerRetention from "./components/Blogs/CustomerRetention";
import HighFunctioningTeams from "./components/Blogs/HighFunctioningTeams";
import Careers from "./components/Career";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Home from "./components/Home";
import GraphicsDesigner from "./components/JD/GraphicsDesigner";
import ReactDeveloper from "./components/JD/ReactDeveloper";
import SocialMedia from "./components/JD/SocialMedia";

function Router() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />

        {/* blogs */}
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/customer-retention-acquisition" element={<CustomerRetention />} />
        <Route path="/blog/high-functioning-teams" element={<HighFunctioningTeams />} />

        {/* careers */}
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/react-developer" element={<ReactDeveloper />} />
        <Route path="/careers/graphics-designer" element={<GraphicsDesigner />} />
        <Route path="/careers/social-media-manager" element={<SocialMedia />} />
        
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default Router;
