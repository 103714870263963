import { Link } from "react-router-dom";

function BlogGrid() {
  return (
    <section id="about">
      <div className="container">
        <div className="row about-cols">
          <div className="col-md-4 wow fadeInUp">
            <div className="about-col">
              <Link to="/blog/customer-retention-acquisition" name="blog">
                <div className="grid">
                  <div className="entry-media">
                    <img src="/img/blogs/customer-retention-cover.jpg" alt="" />
                  </div>
                  <div className="entry-body">
                    <span className="cat">Leads</span>
                    <h3>
                      <Link
                        to="/blog/customer-retention-acquisition"
                        name="blog"
                      >
                        Customer Retention or Customer Acquisition
                      </Link>
                    </h3>
                    <p>
                      When it comes to making business decisions, there are many
                      things you must consider. One of them is whether you
                      should focus on customer acquisition or retention...
                    </p>
                    <div className="read-more-date">
                      <Link
                        to="/blog/customer-retention-acquisition"
                        name="blog"
                      >
                        Read More..
                      </Link>
                      {/* <span className="date">3 Hours ago</span> */}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="col-md-4 wow fadeInUp">
            <div className="about-col">
              <Link to="/blog/high-functioning-teams" name="blog">
                <div className="grid">
                  <div className="entry-media">
                    <img src="/img/blogs/teams-cover.jpg" alt="" />
                  </div>
                  <div className="entry-body">
                    <span className="cat">Teams</span>
                    <h3>
                      <Link
                        to="/blog/high-functioning-teams"
                        name="blog"
                      >
                        High Functioning Teams
                      </Link>
                    </h3>
                    <p>
                      The most effective teams are those that communicate
                      freely, have a shared sense of purpose, and feel a sense
                      of psychological safety. In this post, we'll discuss the
                      three essential elements of effective...
                    </p>
                    <div className="read-more-date">
                      <Link
                        to="/blog/high-functioning-teams"
                        name="blog"
                      >
                        Read More..
                      </Link>
                      {/* <span className="date">3 Hours ago</span> */}
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          {/* <div className="col-md-4 wow fadeInUp" data-wow-delay="0.2s">
            <div className="about-col">
              <div className="grid">
                <div className="entry-media">
                  <img
                    src="http://csshint.com/wp-content/uploads/2018/04/business-card-1.jpg"
                    alt=""
                  />
                </div>
                <div className="entry-body">
                  <span className="cat">Data Science</span>
                  <h3>
                    <a
                      href="http://csshint.com/free-modern-business-card-psd-templates/"
                      target="_blank"
                    >
                      DevOps and Data Science
                    </a>
                  </h3>
                  <p>
                    Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut
                    odit aut..
                  </p>
                  <div className="read-more-date">
                    <a
                      href="http://csshint.com/free-modern-business-card-psd-templates/"
                      target="_blank"
                    >
                      Read More..
                    </a>
                    <span className="date">3 Hours ago</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 wow fadeInUp" data-wow-delay="0.2s">
            <div className="about-col">
              <div className="grid">
                <div className="entry-media">
                  <img
                    src="http://csshint.com/wp-content/uploads/2018/04/business-card-1.jpg"
                    alt=""
                  />
                </div>
                <div className="entry-body">
                  <span className="cat">Teams</span>
                  <h3>
                    <a
                      href="http://csshint.com/free-modern-business-card-psd-templates/"
                      target="_blank"
                    >
                      High Functioning Teams
                    </a>
                  </h3>
                  <p>
                    The most effective teams are those that communicate freely,
                    have a shared sense of purpose, and feel a sense of
                    psychological safety...
                  </p>
                  <div className="read-more-date">
                    <a
                      href="http://csshint.com/free-modern-business-card-psd-templates/"
                      target="_blank"
                    >
                      Read More..
                    </a>
                    <span className="date">3 Hours ago</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 wow fadeInUp" data-wow-delay="0.2s">
            <div className="about-col">
              <div className="grid">
                <div className="entry-media">
                  <img
                    src="http://csshint.com/wp-content/uploads/2018/04/business-card-1.jpg"
                    alt=""
                  />
                </div>
                <div className="entry-body">
                  <span className="cat">Teams</span>
                  <h3>
                    <a
                      href="http://csshint.com/free-modern-business-card-psd-templates/"
                      target="_blank"
                    >
                      High Functioning Teams
                    </a>
                  </h3>
                  <p>
                    The most effective teams are those that communicate freely,
                    have a shared sense of purpose, and feel a sense of
                    psychological safety...
                  </p>
                  <div className="read-more-date">
                    <a
                      href="http://csshint.com/free-modern-business-card-psd-templates/"
                      target="_blank"
                    >
                      Read More..
                    </a>
                    <span className="date">3 Hours ago</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

export default BlogGrid;
