import { useEffect } from "react";
import $ from "jquery";
import CareersGrid from "./CareersGrid";

function Careers() {
  useEffect(() => {
    $("html, body").animate({ scrollTop: "0" }, 0);
  }, []);
  return (
    <main id="main">
      <section id="intro" className="custom-page">
        <div className="intro-container">
          <div
            id="introCarousel"
            className="carousel  slide carousel-fade"
            data-ride="carousel"
          >
            <div className="carousel-inner" role="listbox">
              <div
                className="carousel-item active custom-content"
                style={{ backgroundImage: "url('img/intro-carousel/2.jpg')"}}
              >
                <div className="carousel-container">
                  <div className="carousel-content">
                    <h2>Careers</h2>
                    {/* <p>
                      Whether you are looking for a complete software
                      development solution, or are simply looking for some
                      guidance, Techknock is your one stop shop. Our team of IT
                      consultants specialize in business consulting,
                      implementing and supporting the analysis, design and
                      development of enterprise class bespoke software systems.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CareersGrid />
    </main>
  );
}

export default Careers;
