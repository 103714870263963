import { useEffect } from "react";
import $ from "jquery";

function GraphicsDesigner() {
  useEffect(() => {
    $("html, body").animate({ scrollTop: "0" }, 0);
  }, []);

  return (
    <>
      <section id="intro" className="custom-page">
        <div className="intro-container">
          <div
            id="introCarousel"
            className="carousel  slide carousel-fade"
            data-ride="carousel"
          >
            <div className="carousel-inner" role="listbox">
              <div
                className="carousel-item active custom-content"
                style={{
                  backgroundImage: "url('/img/careers/graphics-bkg.jpg')",
                }}
              >
                <div className="carousel-container">
                  <div className="carousel-content">
                    <h2>Graphics Designer</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services">
        <div className="container">
          <div className="section-header wow fadeInUp">
            <h3>Job Description</h3>
            <p style={{ textAlign: "justify", paddingBottom: "0px" }}>
              We are looking for a Graphic Designer to to join our front-end
              development team at{" "}
              <b>
                <a href="https://techknock.co.uk/">Techknock</a>
              </b>{" "}
              to create engaging and on-brand graphics for a variety of media.
              <br />
              Responsibilities includes the entire process of defining
              requirements, visualizing and creating graphics including
              illustrations, logos, layouts and photos.
              <br />
              <br />
            </p>
            <h3>Responsibilities</h3>
            <p style={{ textAlign: "left", paddingBottom: "0px" }}>
              <li>
              Study design briefs and determine requirements
              </li>
              <li>Schedule projects and define budget constraints</li>
              <li>
              Conceptualize visuals based on requirements
              </li>
              <li>
              Develop illustrations, logos and other designs using software or by hand
              </li>
              <li>
              Ensure final graphics and layouts are visually appealing and on-brand
              </li>
            </p>
            <div className="row">
              <div className="col">
                <img
                  src="/img/careers/graphics-design-detail.jpg"
                  alt=""
                  className="graphics-design-detail.jpg"
                  style={{ width: "inherit", marginBottom: "5%" }}
                />
              </div>
            </div>
            <h2>
              Send your resume at{" "}
              <b>
                <a href="mailto:info@techknock.com">Techknock</a>
              </b>{" "}
            </h2>
          </div>
        </div>
      </section>
    </>
  );
}

export default GraphicsDesigner;
