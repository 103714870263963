function Footer() {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-info">
              <img src="/img/logo.png" alt="techknock logo" />
              {/* <p>Technology knocking at your door</p> */}
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Explore</h4>
              <ul>
                <li>
                  <i className="ion-ios-arrow-right"></i>{" "}
                  <a href="#intro">Home</a>
                </li>
                <li>
                  <i className="ion-ios-arrow-right"></i>{" "}
                  <a href="#about">About us</a>
                </li>
                <li>
                  <i className="ion-ios-arrow-right"></i>{" "}
                  <a href="#services">Services</a>
                </li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Resources</h4>
              <ul>
                <li>
                  <i className="ion-ios-arrow-right"></i>{" "}
                  <a href="/#contact">Contact</a>
                </li>
                <li>
                  <i className="ion-ios-arrow-right"></i>{" "}
                  <a href="/blog">Blog</a>
                </li>
                <li>
                  <i className="ion-ios-arrow-right"></i>{" "}
                  <a href="/careers">Careers</a>
                </li>
              </ul>
              {/* <ul>
                <li>
                  <i className="ion-ios-arrow-right"></i>{" "}
                  <a href="#careers">Careers</a>
                </li>
              </ul> */}
            </div>

            <div className="col-lg-3 col-md-6 footer-contact">
              <h4>Contact Us</h4>
              <p>
                Barking Magistrates Court
                <br />
                44-48 East Street
                <br />
                London, United Kingdom
                <br />
                <strong>Phone:</strong> +44 7495 454214
                <br />
                <strong>Email:</strong> info@techknock.co.uk
                <br />
              </p>
              {/* <div className="social-links">
                  <a href="#services" className="twitter"><i className="fa fa-twitter"></i></a>
                  <a href="#services" className="facebook"><i className="fa fa-facebook"></i></a>
                  <a href="#services" className="instagram"><i className="fa fa-instagram"></i></a>
                  <a href="#services" className="google-plus"><i className="fa fa-google-plus"></i></a>
                  <a href="#services" className="linkedin"><i className="fa fa-linkedin"></i></a>
                </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          2023 &copy; Copyright <strong>Techknock</strong>. All Rights Reserved
        </div>
      </div>
    </footer>
  );
}

export default Footer;
