import { useEffect } from "react";

function Testimonials() {
  useEffect(() => {
    window.$("#customers-testimonials").owlCarousel({
      loop: true,
      center: true,
      items: 3,
      margin: 0,
      autoplay: true,
      dots: true,
      autoplayTimeout: 8500,
      smartSpeed: 450,
      responsive: {
        0: {
          items: 1,
        },
        768: {
          items: 2,
        },
        1170: {
          items: 3,
        },
      },
    });
  });
  return (
    <section id="testimonials" className="section-bg wow fadeInUp">
      <div className="container">
        <div className="section-header">
          <h3>Client Reviews</h3>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div id="customers-testimonials" className="owl-carousel">
              <div className="item">
                <div className="shadow-effect">
                  <img
                    className="img-circle"
                    src="/img/clients/tethyr.png"
                    alt=""
                  />
                  <p>
                    A great team to work with. Will be sending more work their
                    way. Communication skills are excellent and if we needed
                    work that was outside of their skillset, put me in contact
                    with the right developer to help out our project.
                  </p>
                </div>
                <div className="testimonial-name">Nick</div>
              </div>
              <div className="item">
                <div className="shadow-effect">
                  <img
                    className="img-circle"
                    src="/img/clients/bant.png"
                    alt=""
                  />
                  <p>
                    Techknock team supported our dev team as a Full Stack
                    Developers in node.js and react for almost a year and I would
                    highly recommend them for anyone considering them for a
                    project.
                  </p>
                </div>
                <div className="testimonial-name">Jaclyn Crutis</div>
              </div>

              <div className="item">
                <div className="shadow-effect">
                  <img
                    className="img-circle"
                    src="/img/clients/client-6.png"
                    alt=""
                  />
                  <p>
                    Team is an excellent in communication and very skilled at
                    what they do. They have a creative flair for layout. Their work
                    surpassed my expectations for the website. I would recommend
                    them without hesitation.
                  </p>
                </div>
                <div className="testimonial-name">Rose Peak</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
