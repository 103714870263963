function Carousel() {
  return (
    <section id="intro">
      <div className="intro-container">
        <div
          id="introCarousel"
          className="carousel  slide carousel-fade"
          data-ride="carousel"
        >
          <ol className="carousel-indicators"></ol>
          <div className="carousel-inner" role="listbox">
            <div
              className="carousel-item active"
              style={{ backgroundImage: "url('img/intro-carousel/1.jpg')" }}
            >
              <div className="carousel-container">
                <div className="carousel-content">
                  <h2>Techknock</h2>
                  <p>
                    At Techknock, we specialize in delivering cutting-edge
                    development services and comprehensive digital
                    transformation solutions. Our expertise in modern
                    technologies ensures your business stays ahead in an
                    ever-evolving digital landscape.
                  </p>
                  <a href="#contact" className="btn-get-started scrollto">
                    Make it happen
                  </a>
                </div>
              </div>
            </div>

            <div
              className="carousel-item"
              style={{ backgroundImage: "url('img/intro-carousel/2.jpg')" }}
            >
              <div className="carousel-container">
                <div className="carousel-content">
                  <h2>Transform business</h2>
                  <p>
                    Businesses of all sizes and across all sectors turn to
                    Techknock for digital solutions. We create solutions that
                    become part of our clients' business infrastructure,
                    enabling them to focus on their core activities.
                    {/* Helping businesses truly succeed. Not surviving, not getting by. But
                    really succeeding. */}
                  </p>
                  <a href="#contact" className="btn-get-started scrollto">
                    Enhance business
                  </a>
                </div>
              </div>
            </div>

            {/* <div
              className="carousel-item"
              style={{ backgroundImage: "url('img/intro-carousel/3.jpg')" }}
            >
              <div className="carousel-container">
                <div className="carousel-content">
                  <h2>Best Software solutions and Consultancy</h2>
                  <p>
                    Techknock is a software consultancy and development agency
                    focused on delivering high-quality products through
                    attention to detail, personalised service and a commitment
                    to great communication.
                  </p>
                  <a href="#contact" className="btn-get-started scrollto">
                    Explore expertise
                  </a>
                </div>
              </div>
            </div> */}

            <div
              className="carousel-item"
              style={{ backgroundImage: "url('img/intro-carousel/4.jpg')" }}
            >
              <div className="carousel-container">
                <div className="carousel-content">
                  <h2>Nothing but the best is good enough</h2>
                  <p>
                    We are professional, dedicated and customer-oriented team,
                    somebody you can count on to craft great solutions. We help
                    companies to deliver successful projects on time, within
                    budget, and to meet customer expectations.
                  </p>
                  <a href="#contact" className="btn-get-started scrollto">
                    Imagine What's Possible
                  </a>
                </div>
              </div>
            </div>

            <div
              className="carousel-item"
              style={{ backgroundImage: "url('img/intro-carousel/5.jpg')" }}
            >
              <div className="carousel-container">
                <div className="carousel-content">
                  <h2>Tech knocks at your door</h2>
                  <p>
                    We empower organizations to succeed through the strategic
                    use of technology. Our mission is to help our clients and
                    partners achieve higher levels of success and satisfaction,
                    creating lasting value for everyone involved.
                  </p>
                  <a href="#contact" className="btn-get-started scrollto">
                    Talk to us
                  </a>
                </div>
              </div>
            </div>
          </div>

          <a
            className="carousel-control-prev"
            href="#introCarousel"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon ion-chevron-left"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>

          <a
            className="carousel-control-next"
            href="#introCarousel"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon ion-chevron-right"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Carousel;
