import { useEffect } from "react";
import $ from "jquery";

function SocialMedia() {
  useEffect(() => {
    $("html, body").animate({ scrollTop: "0" }, 0);
  }, []);

  return (
    <>
      <section id="intro" className="custom-page">
        <div className="intro-container">
          <div
            id="introCarousel"
            className="carousel  slide carousel-fade"
            data-ride="carousel"
          >
            <div className="carousel-inner" role="listbox">
              <div
                className="carousel-item active custom-content"
                style={{
                  backgroundImage:
                    "url('/img/blogs/attract-retention-customers.jpg')",
                }}
              >
                <div className="carousel-container">
                  <div className="carousel-content">
                    <h2>Social Media Manager</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services">
        <div className="container">
          <div className="section-header wow fadeInUp">
            <h3>Job Description</h3>
            <p style={{ textAlign: "justify", paddingBottom: "0px" }}>
              Social Media Manager responsibilities include performing research
              on current benchmark trends and audience preferences, Designing
              and implementing social media strategy to align with business
              goals and setting specific objectives and reporting on ROI.
              <br />
              We are looking for a talented Social media manager to administer
              our social media accounts. You will be responsible for creating
              original text and video content, managing posts and responding to
              followers. You will manage our{" "}
              <b>
                <a href="https://techknock.co.uk/">Techknock</a>
              </b>{" "}
              image in a cohesive way to achieve our marketing goals. <br />
              <br />
            </p>
            <h3>Responsibilities</h3>
            <p style={{ textAlign: "left", paddingBottom: "0px" }}>
              <li>
                Perform research on current benchmark trends and audience
                preferences
              </li>
              <li>
                Design and implement social media strategy to align with
                business goals
              </li>
              <li>Set specific objectives and report on ROI</li>
              <li>
                Generate, edit, publish and share engaging content daily (e.g.
                original text, photos, videos and news)
              </li>
              <li>Monitor SEO and web traffic metrics</li>
              <li>
                Collaborate with other teams, like marketing, sales and customer
                service to ensure brand consistency
              </li>
              <li>
                Communicate with followers, respond to queries in a timely
                manner and monitor customer reviews
              </li>
              <li>
                Oversee social media accounts' design (e.g. Linkedin timeline
                cover, profile pictures and blog layout)
              </li>
              <li>
                Suggest and implement new features to develop brand awareness,
                like promotions and competitions
              </li>
              <li>
                Stay up-to-date with current technologies and trends in social
                media, design tools and applications
              </li>
            </p>
            <div className="row">
              <div className="col">
                <img
                  src="/img/careers/social-media-detail.jpg"
                  alt=""
                  className="social-media-detail.jpeg"
                  style={{ width: "inherit", marginBottom: "5%" }}
                />
              </div>
            </div>
            <p style={{ textAlign: "justify", paddingBottom: "0px" }}>
              Ultimately, you should be able to handle{" "}
              <b>
                <a href="https://techknock.co.uk/">Techknock</a>
              </b>{" "}
              social media presence ensuring high levels of web traffic and
              customer engagement.
            </p>
            <h2>
              Send your resume at{" "}
              <b>
                <a href="mailto:info@techknock.com">Techknock</a>
              </b>{" "}
            </h2>
          </div>
        </div>
      </section>
    </>
  );
}

export default SocialMedia;
