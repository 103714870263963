import { useEffect } from "react";

function Clients() {
  useEffect(() => {
    window.$(".clients-carousel").owlCarousel({
      autoplay: true,
      dots: true,
      loop: true,
      responsive: { 0: { items: 2 }, 768: { items: 4 }, 900: { items: 6 } },
    });
  }, []);
  return (
    <section id="facts" className="wow fadeInUp">
      <div className="container">
        <div className="section-header">
          <h3>Clients</h3>
          <p>
            We are professional, dedicated and customer-oriented team, somebody
            you can count on to craft great solutions. We help companies to
            deliver successful projects on time, within budget, and to meet
            customer expectations.
          </p>
        </div>

        <div className="owl-carousel clients-carousel" style={{paddingBottom: "60px"}}>
          <img src="/img/clients/bant.png" alt="" />
          <img src="/img/clients/zopto.png" alt="" />
          <img src="/img/clients/steller.png" alt="" />
          <img src="/img/clients/tethyr.png" alt="" />
          <img src="/img/clients/client-2.png" alt="" />
          <img src="/img/clients/client-6.png" alt="" />
          <img src="/img/clients/client-3.png" alt="" />
        </div>
      </div>
    </section>
  );
}

export default Clients;
