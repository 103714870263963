import { Link } from "react-router-dom";


function CareersGrid() {

  return (
    <section id="about">
      <div className="container">
        <div className="row about-cols">
          <div className="col-md-4 wow fadeInUp">
            <div className="about-col">
              <Link to="/careers/social-media-manager" name="careers">
                <div className="grid">
                  <div className="entry-media">
                    <img src="/img/careers/social-media.jpg" alt="" />
                  </div>
                  <div className="entry-body">
                    <span className="cat">Intern / Contract</span>
                    <h3>
                      <Link
                        to="/careers/social-media-manager"
                        name="careers"
                      >
                        Social Media Manager
                      </Link>
                    </h3>
                    <p>
                      A Social Media Manager is responsible for overseeing a
                      Techknock's interactions with the public by implementing
                      social media platforms' content strategies...
                    </p>
                    <div className="read-more-date">
                      <Link
                        to="/careers/social-media-manager"
                        name="careers"
                      >
                        Job Description
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="col-md-4 wow fadeInUp">
            <div className="about-col">
              <Link to="/careers/graphics-designer" name="careers">
                <div className="grid">
                  <div className="entry-media">
                    <img src="/img/careers/graphics-designer.jpg" alt="" />
                  </div>
                  <div className="entry-body">
                    <span className="cat">Intern / Contract</span>
                    <h3>
                      <Link
                        to="/careers/graphics-designer"
                        name="careers"
                      >
                        Graphics Designer
                      </Link>
                    </h3>
                    <p>
                      The Graphics Designer is responsible for the entire
                      process of defining requirements, visualizing and creating
                      graphics including illustrations, logos, layouts and
                      photos...
                    </p>
                    <div className="read-more-date">
                      <Link
                        to="/careers/graphics-designer"
                        name="careers"
                      >
                        Job Description
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="col-md-4 wow fadeInUp">
            <div className="about-col">
              <Link to="/careers/react-developer" name="careers">
                <div className="grid">
                  <div className="entry-media">
                    <img src="/img/careers/react-developer.jpg" alt="" />
                  </div>
                  <div className="entry-body">
                    <span className="cat">Intern / Contract</span>
                    <h3>
                      <Link
                        to="/careers/react-developer"
                        name="careers"
                      >
                        React Developer
                      </Link>
                    </h3>
                    <p>
                      The Graphics Designer is responsible for the entire
                      process of defining requirements, visualizing and creating
                      graphics including illustrations, logos, layouts and
                      photos...
                    </p>
                    <div className="read-more-date">
                      <Link
                        to="/careers/react-developer"
                        name="careers"
                      >
                        Job Description
                      </Link>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CareersGrid;
