import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

function Contact() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const sendEmail = (data) => {
    emailjs
      .sendForm(
        "service_ze5skp3",
        "template_0r940zn",
        form.current,
        "RYIQ9jYIy5REqKtYj"
      )
      .then(
        (result) => {
          toast.success("Email Sent Successfully!");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const form = useRef();
  return (
    <section id="contact" className="section-bg wow fadeInUp">
      <div className="container">
        <div className="section-header">
          <h3>Contact Us</h3>
          {/* <p>
            Get in touch with us
          </p> */}
        </div>

        <div className="row contact-info">
          <div className="col-md-4">
            <div className="contact-address">
              <i className="ion-ios-location-outline"></i>
              <h3>Address</h3>
              <address>
                Barking Magistrates Court
                <br />
                44-48 East Street
                <br /> London, UK
              </address>
            </div>
          </div>

          <div className="col-md-4">
            <div className="contact-phone">
              <i className="ion-ios-telephone-outline"></i>
              <h3>Phone Number</h3>
              <p>
                <a href="tel:+447495454214">+44 7495 454214</a>
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="contact-email">
              <i className="ion-ios-email-outline"></i>
              <h3>Email</h3>
              <p>
                <a href="mailto:info@techknock.co.uk">info@techknock.co.uk</a>
              </p>
            </div>
          </div>
        </div>

        <div className="form">
          <div id="sendmessage">Your message has been sent. Thank you!</div>
          <div id="errormessage"></div>
          <form
            className="contactForm"
            ref={form}
            onSubmit={handleSubmit(sendEmail)}
          >
            <div className="form-row">
              <div className="form-group col-md-6">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Name"
                  {...register("name", { required: true })}
                />
                {errors.name && (
                  <span className="field-error">Name is required</span>
                )}
              </div>
              <div className="form-group col-md-6">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Your Email"
                  {...register("email", { required: true })}
                />
                {errors.email && (
                  <span className="field-error">Email Address is required</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="subject"
                placeholder="Subject"
                {...register("subject", { required: true })}
              />
              {errors.subject && (
                <span className="field-error">Subject is required</span>
              )}
            </div>
            <div className="form-group">
              <textarea
                className="form-control"
                name="message"
                rows="5"
                placeholder="Message"
                {...register("message", { required: true })}
              ></textarea>
              {errors.message && (
                <span className="field-error">Messages is required</span>
              )}
            </div>
            <div className="text-center">
              <button type="submit">Request Free Consultation</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Contact;
