function About() {
  return (
    <section id="about">
      <div className="container">
        <div className="section-header">
          <h3>About Us</h3>
          <p>
            At Techknock, we excel in providing top-tier development services
            and innovative digitalization solutions. Our team harnesses the
            power of creativity and modern technologies to drive your business
            forward. Through creative problem-solving and strategic planning, we
            ensure your digital transformation is seamless and impactful. <br />{" "}
            <br />{" "}
            <b>
              Helping businesses truly succeed. Not surviving, not getting by.
              But really succeeding.
            </b>
          </p>
        </div>

        {/* <div className="row about-cols">
          <div className="col-md-4 wow fadeInUp">
            <div className="about-col">
              <div className="img">
                <img
                  src="/img/about-mission.jpg"
                  alt=""
                  className="img-fluid"
                />
                <div className="icon">
                  <i className="ion-ios-speedometer-outline"></i>
                </div>
              </div>
              <h2 className="title">
                <a href="#services">Our Mission</a>
              </h2>
              <p>
                Techknock mission is to deliver high quality, cost effective
                solutions to our customers within tight timeframes. Techknock
                mission is to provide business leaders with a range of
                cost-effective and efficient technology solutions.
              </p>
            </div>
          </div>

          <div className="col-md-4 wow fadeInUp" data-wow-delay="0.1s">
            <div className="about-col">
              <div className="img">
                <img src="/img/about-plan.jpg" alt="" className="img-fluid" />
                <div className="icon">
                  <i className="ion-ios-list-outline"></i>
                </div>
              </div>
              <h2 className="title">
                <a href="#services">Our Plan</a>
              </h2>
              <p>
                We plan to deliver software products that are excellent and
                become a thought leader & trendsetter in the software industry.
                Our plan is to improve the process of software delivery and the
                overall experience of using applications.
              </p>
            </div>
          </div>

          <div className="col-md-4 wow fadeInUp" data-wow-delay="0.2s">
            <div className="about-col">
              <div className="img">
                <img src="/img/about-vision.jpg" alt="" className="img-fluid" />
                <div className="icon">
                  <i className="ion-ios-eye-outline"></i>
                </div>
              </div>
              <h2 className="title">
                <a href="#services">Our Vision</a>
              </h2>
              <p>
                Techknock Vision is to create Products for the Next Generation!
                Our goal is to meet both short-term needs of our clients by
                providing quick turnaround and low cost solutions as well as
                long-term benefits.
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default About;
