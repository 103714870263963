import "./App.css";
import Router from "./Route";

function App() {
  return (
    <div className="App">
      <Router />
      <a href="/#" className="back-to-top" id="back-to-top">
        <i className="fa fa-chevron-up"></i>
      </a>
    </div>
  );
}

export default App;
