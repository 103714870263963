import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Header() {
  const [hash, setHash] = useState("");
  useEffect(() => {
    if (window.$("#nav-menu-container").length) {
      var $mobile_nav = window.$("#nav-menu-container").clone().prop({
        id: "mobile-nav",
      });
      $mobile_nav.find("> ul").attr({
        class: "",
        id: "",
      });
      window.$("body").append($mobile_nav);
      window
        .$("body")
        .prepend(
          '<button type="button" id="mobile-nav-toggle"><i class="fa fa-bars fa-lg"></i></button>'
        );
      window.$("body").append('<div id="mobile-body-overly"></div>');
      window
        .$("#mobile-nav")
        .find(".menu-has-children")
        .prepend('<i class="fa fa-chevron-down"></i>');
    } else if (window.$("#mobile-nav, #mobile-nav-toggle").length) {
      window.$("#mobile-nav, #mobile-nav-toggle").hide();
    }
  }, []);

  useEffect(() => {
    if (hash !== "blog" && hash !== "careers") {
      var target = window.$(hash);
      if (target.length) {
        var top_space = 0;

        if (window.$('#header').length) {
          top_space = window.$('#header').outerHeight();

          if( ! window.$('#header').hasClass('header-fixed') ) {
            top_space = top_space - 20;
          }
        }

        window.$('html, body').animate({
          scrollTop: target.offset().top - top_space
        }, 1500, 'easeInOutExpo');
        if (window.$('body').hasClass('mobile-nav-active')) {
          window.$('body').removeClass('mobile-nav-active');
          window.$('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
          window.$('#mobile-body-overly').fadeOut();
        }
      }
    }
  }, [hash]);

  useEffect(()=>{
    if (!window.location.pathname.includes("/blog") && !window.location.pathname.includes("/careers")) {
      setHash(window.location.hash);
    }
    if(window.location.pathname.includes("/blog")){
          setHash("blog");
    }
    if(window.location.pathname.includes("/careers")){
      setHash("careers");
}
  },[]);

  return (
    <>
      <div id="header">
        <div className="container-fluid">
          <div id="logo" className="pull-left">
            <a href="/">
              <img id="main-logo" src="/img/logo.png" alt="techknock logo" />
            </a>
          </div>

          <nav id="nav-menu-container">
            <ul className="nav-menu">
              <li className={hash === "#intro" || hash === "" ? 'menu-active' : ''}>
              <Link
                  className="menu-item"
                  to="/#intro"
                  name="home"
                  onClick={() => {
                    setHash("#intro");
                  }}
                >
                  Home
                </Link>
              </li>
              <li className={hash === "#about" ? 'menu-active' : ''}>
              <Link
                  className="menu-item"
                  to="/#about"
                  name="about"
                  onClick={() => {
                    setHash("#about");
                  }}
                >
                  About Us
                </Link>
              </li>
              <li className={hash === "#services" ? 'menu-active' : ''}>
              <Link
                  className="menu-item"
                  to="/#services"
                  name="services"
                  onClick={() => {
                    setHash("#services");
                  }}
                >
                  Services
                </Link>
              </li>
              {/* <li className={hash === "#testimonials" ? 'menu-active' : ''}>
              <Link
                  className="menu-item"
                  to="/#testimonials"
                  name="testimonials"
                  onClick={() => {
                    setHash("#testimonials");
                  }}
                >
                  Testimonials
                </Link>
              </li> */}
              {/* <li className={hash === "#team" ? 'menu-active' : ''}>
              <Link
                  className="menu-item"
                  to="/#team"
                  name="team"
                  onClick={() => {
                    setHash("#team");
                  }}
                >
                  Team
                </Link>
              </li> */}
              <li className={hash === "blog" ? 'menu-active' : ''}>
              <Link
                  to="/blog"
                  name="blog"
                  onClick={() => {
                    setHash("blog");
                  }}
                >
                  Blog
                </Link>
              </li>
              <li className={hash === "careers" ? 'menu-active' : ''}>
              <Link
                  to="/careers"
                  name="careers"
                  onClick={() => {
                    setHash("careers");
                  }}
                >
                  Careers
                </Link>
              </li>
              {/* <li>
                <a className="menu-item" href="#portfolio">
                  Portfolio
                </a>
              </li> */}
              {/* <li>
                <a className="menu-item" href="#careers">
                  Careers
                </a>
              </li> */}
              <li className={hash === "#contact" ? 'menu-active' : ''}>
              <Link
                  className="menu-item"
                  to="/#contact"
                  name="contact"
                  onClick={() => {
                    setHash("#contact");
                  }}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Header;
